import { HuddleWebClient } from './HuddleWebClient';

export class HuddleClient extends HuddleWebClient {
  protected _projectId: string;

  constructor() {
    super();
  }

  // useHuddle01()
  initialize = (projectId: string) => {
    if (!projectId) {
      throw new Error('projectId is missing');
    }

    this._projectId = projectId;
    this.emit('app:initialized');
    this.logger.info('app:initialized');
  };

  // useLobby()
  joinLobby = (roomId: string, accessToken?: string) => {
    if (!this._projectId) {
      throw new Error('projectId is missing, please initialize the SDK first');
    }
    if (!roomId) {
      throw new Error('roomId is missing');
    }

    this._joinLobby(this._projectId, roomId);
  };

  leaveLobby = () => {
    this._disconnectSocket();
  };

  // useRoom()
  joinRoom = (consumeOnJoin: boolean = true) => {
    if (!this._socket) {
      throw new Error('Lobby not joined yet, please join lobby first');
    }

    this._joinRoom(consumeOnJoin);
  };

  leaveRoom = () => {
    this._leaveRoom();
  };

  //useAudio()
  fetchAudioStream = async () => {
    if (this._audioStream) {
      console.info('Audio stream already exists, returning the same');

      return this._audioStream;
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      this._audioStream = stream;
      this.emit('app:mic-on', stream);
      this.logger.info('app:cam-on', stream);

      return stream;
    } catch (error) {
      console.error('Audio Request Error; Reason:', error);
      throw new Error('Unable to fetch audio stream');
    }
  };

  stopAudioStream = () => {
    if (this._audioProducer) {
      console.log('Audio producer found, stopping audio producer first');
      this.stopProducingAudio();
    }

    if (this._audioStream) {
      this._audioStream.getTracks().forEach(track => track.stop());
      this._audioStream = null;
      this.emit('app:mic-off');
      this.logger.info('app:mic-off');

      return;
    }

    throw new Error('Audio stream not found, fetch audio stream first');
  };

  produceAudio(micStream: MediaStream, peerIds?: string[]) {
    if (!this._socket) {
      throw new Error('Room not joined yet, please join room first');
    }

    this._produceAudioStream(micStream, peerIds);
  }

  stopProducingAudio = () => {
    if (!this._audioProducer) {
      throw new Error('Audio producer not found, produce audio first');
    }

    if (!this._socket) {
      throw new Error('Room not joined yet, please join room first');
    }

    this._requestStopAudioProducer();
  };

  createMicConsumer = (peerId: string) => {
    if (!this._socket) {
      throw new Error('Room not joined yet, please join room first');
    }

    this._createSingleConsumer({
      mediaType: 'mic',
      producerPeerId: peerId,
    });
  };

  closeMicConsumer = (peerId: string) => {
    if (!peerId) {
      throw new Error('peerId is missing');
    }

    this._closeSingleConsumer({
      mediaType: 'mic',
      producerPeerId: peerId,
    });
  };

  // useVideo()
  fetchVideoStream = async () => {
    if (this._videoStream) {
      console.info('Video stream already exists, returning the same');

      return this._videoStream;
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      this._videoStream = stream;
      this.emit('app:cam-on', stream);
      this.logger.info('app:cam-on', stream);

      return stream;
    } catch (error) {
      console.error('Video Request Error; Reason:', error);
      throw new Error('Unable to fetch video stream');
    }
  };

  stopVideoStream = () => {
    if (this._videoProducer) {
      console.log('Video producer found, stopping video producer first');
      this.stopProducingVideo();
    }

    if (this._videoStream) {
      this._videoStream.getTracks().forEach(track => track.stop());
      this._videoStream = null;
      this.emit('app:cam-off');
      this.logger.info('app:cam-off');

      return;
    }

    throw new Error('Video stream not found, fetch video stream first');
  };

  produceVideo(camStream: MediaStream, peerIds?: string[]) {
    if (!this._socket) {
      throw new Error('Room not joined yet, please join room first');
    }

    this._produceVideoStream(camStream, peerIds);
  }

  stopProducingVideo() {
    if (!this._videoProducer) {
      console.log('Video not producing yet');
      return;
    }

    if (!this._socket) {
      throw new Error('Room not joined yet, please join room first');
    }

    this._requestStopVideoProducer();
  }

  createCamConsumer(peerId: string) {
    if (!peerId) {
      throw new Error('peerId is missing');
    }

    this._createSingleConsumer({
      mediaType: 'cam',
      producerPeerId: peerId,
    });
  }

  closeCamConsumer = (peerId: string) => {
    if (!peerId) {
      throw new Error('peerId is missing');
    }

    this._closeSingleConsumer({
      mediaType: 'cam',
      producerPeerId: peerId,
    });
  };

  // usePeers()
  getPeers = () => {
    const peers = Array.from(this._peers, ([_, value]) => {
      return value;
    });

    console.log('thisPeers:', this._peers);

    return peers;
  };

  getPeer = (peerId: string) => {
    const peer = this._peers.get(peerId);

    if (!peer) {
      throw new Error('Peer not found');
    }

    return peer;
  };

  getPeerTracks = (peerId: string) => {
    const peer = this._peers.get(peerId);

    if (!peer) {
      throw new Error(` Peer not found with peerId ${peerId}`);
    }

    const peerConsumerIds = this._peersConsumers.get(peerId);

    console.log({
      peerConsumerIds,
      _peersConsumers: this._peersConsumers,
      _consumers: this._consumers,
    });

    const audioTrack = this._consumers.get(peerConsumerIds.mic)?.track;
    const videoTrack = this._consumers.get(peerConsumerIds.cam)?.track;

    return { audio: audioTrack, video: videoTrack };
  };

  // useRecording()
  startRecording = async (sourceUrl: string) => {
    if (!sourceUrl) {
      throw new Error('sourceUrl is missing');
    }

    return await this._startRecording(sourceUrl);
  };

  stopRecording = (storeOnIpfs: boolean = false) => {
    this._stopRecording(storeOnIpfs);
  };

  // useDisplayName
  setDisplayName = async (displayName: string) => {
    if (!displayName) {
      throw new Error('displayName is missing');
    }

    await this._setDisplayName(displayName);
  };
}
