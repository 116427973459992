// libs
import * as mediasoup from 'mediasoup-client';
import { Producer } from 'mediasoup-client/lib/types';
import { nanoid } from 'nanoid';
import { Socket, io } from 'socket.io-client';
// types
import {
  ICreateConsumerParams,
  ICreateWebRtcTSuccess,
  IJoinSuccessWithRoomData,
  INewPeer,
  IPeerSocketdata,
  IProduceSuccess,
  MediaTypes,
  TCreateSingleConsumer,
  TStartLivestream,
  TTargetPeerIds,
  TUpdateTargetPeerId,
} from 'types/dist/common.types';

// utils
import { getAuthToken } from '../auth';
import { ENDPOINT } from '../constants';
import {
  codecOptionsViaType,
  encodingViaMediaType,
} from '../constants/rtpConstants';
import EnhancedEventEmitter from '../events/EnhancedEventEmitter';
import {
  IConsumer,
  IConsumerEvent,
  IPeer,
  TMediaStream,
  TMediaTypes,
  TRecData,
} from './HuddleWebClientTypes';
import Logger from './Logger';

export type TPeerProduceStart = {
  peerId: string;
  mediaType: TMediaTypes;
  track: MediaStreamTrack;
};

export type THClientEvents = {
  'app:initialized': [];
  'app:cam-on': [TMediaStream];
  'app:cam-off': [];
  'app:mic-on': [TMediaStream];
  'app:mic-off': [];
  'lobby:joined': [string];
  'lobby:failed': [Error];
  'room:joined': [Map<string, IPeer>];
  'room:failed': [any];
  'room:cam-produce-start': [];
  'room:cam-produce-stop': [];
  'room:mic-produce-start': [];
  'room:mic-produce-stop': [];
  'room:peer-joined': [IPeer];
  'room:peer-left': [string];
  'room:peer-produce-start': [TPeerProduceStart];
  'room:peer-produce-stop': [Omit<TPeerProduceStart, 'track'>];
  'room:recording-started': [];
  'room:recording-stopped': [];
  'room:recording-data': [TRecData];
  // 'room:livestream-started': [];
  // 'room:livestream-stopped': [];
};

class SocketClient extends EnhancedEventEmitter<THClientEvents> {
  protected _meId: string;
  protected _roomId: string;
  protected _socket: Socket;
  protected _isRecorder: boolean = false;
  protected logger: Logger = new Logger();

  constructor() {
    super();
  }

  protected _handleConnect() {
    this.emit('lobby:joined', this._roomId);
    this.logger.info('lobby:joined', this._roomId);
  }

  protected _handleDisconnect() {
    // cb({ type: 'CLIENT_LOBBY_FAILED' });
    this.logger.warn('client disconnected');
  }

  /**
   * @description - Handle Connection Error due to some reason ( like server down, network issue etc )
   * @param error - Error object this is native Error Object of Node.js
   */
  protected _handleConnectError = (error: Error) => {
    this.emit('lobby:failed', error);
    this.logger.error('lobby:failed', error);
    // cb({ type: 'CLIENT_LOBBY_FAILED' });
  };
}

class MediasoupClient extends SocketClient {
  protected _videoStream: MediaStream;
  protected _audioStream: MediaStream;

  protected _device: mediasoup.types.Device;
  protected _sendTransport: mediasoup.types.Transport;
  protected _recvTransport: mediasoup.types.Transport;
  protected _audioProducer: mediasoup.types.Producer;
  protected _videoProducer: mediasoup.types.Producer;
  protected _shareVideoProducer: mediasoup.types.Producer;
  protected _shareAudioProducer: mediasoup.types.Producer;
  protected _consumers: Map<string, mediasoup.types.Consumer> = new Map();
  protected _createConsumersOnJoin: boolean = true;
  protected _peers: Map<string, IPeer>;
  protected _peersConsumers: Map<string, Record<TMediaTypes, string>> =
    new Map();
  protected _turn = [
    {
      username: 'test-turn',
      urls: 'turn:turn.huddle01.com:443?transport=udp',
      credential: 'test-turn',
    },
    {
      username: 'test-turn',
      urls: 'turn:turn.huddle01.com:443?transport=tcp',
      credential: 'test-turn',
    },
  ];

  constructor() {
    super();
  }

  private _produce = async (
    track: MediaStreamTrack,
    type: TMediaTypes,
    targetPeerIds: TTargetPeerIds = '*'
  ) => {
    if (!this._sendTransport) {
      this.logger.warn('produce() | sendTransport is undefined');
      return;
    }
    if (!(this._device && this._device.rtpCapabilities.codecs)) {
      return;
    }
    const codecViaMediaType = {
      cam: this._device.rtpCapabilities.codecs.find(
        codec =>
          codec.mimeType.toLowerCase() === 'video/h264' &&
          codec.parameters['profile-level-id'] === '42e034'
      ),
      'share-video': this._device.rtpCapabilities.codecs.find(
        codec =>
          codec.mimeType.toLowerCase() === 'video/h264' &&
          codec.parameters['profile-level-id'] === '42e034'
      ),
      mic: undefined,
      'share-audio': undefined,
    };

    const producer = await this._sendTransport.produce({
      track: track as MediaStreamTrack,
      encodings: encodingViaMediaType[type],
      codecOptions: codecOptionsViaType[track.kind as 'audio' | 'video'],
      codec: codecViaMediaType[type],
      stopTracks: false,
      zeroRtpOnPause: true,
      disableTrackOnPause: true,
      appData: {
        type,
        share: type === 'share-audio' || type === 'share-video',
        targetPeerIds,
      },
    });

    return producer;
  };

  protected _produceAudioStream = async <T>(
    stream: MediaStream,
    targetPeerIds: TTargetPeerIds = '*'
  ) => {
    try {
      if (!stream) {
        this.logger.warn('produceAudioStream() | stream is undefined');
        return;
      }

      if (!this._audioStream) {
        this._audioStream = stream;
      }

      if (this._audioProducer) {
        this.logger.warn(
          'handleCreateWebRTC() | Audio Producer already exists'
        );
        return;
      }

      const track = stream.getAudioTracks()[0];

      if (!track) {
        this.logger.warn('enableMic() | Track is undefined');
        return;
      }

      const producer = await this._produce(track, 'mic', targetPeerIds);

      if (!producer) {
        this.logger.warn('enableMic() | Producer create error');
        return;
      }

      if (!producer.track) {
        this.logger.warn('enableMic() | Producer track is undefined');

        return;
      }

      this._audioProducer = producer;

      this._audioProducer.on('trackended', () => {
        this.logger.warn('produceAudioStream() | trackended for mic');
        this._stopAudioProducer().catch(this.logger.error);
      });

      this._audioProducer.on('transportclose', () => {
        this.logger.warn('produceAudioStream() | transportclose for mic');
        this._stopAudioProducer().catch(this.logger.error);
      });

      this._audioProducer.on('@replacetrack', () => {
        this.logger.warn('produceAudioStream() | @replacetrack for mic');
      });

      this._audioProducer.on('@close', () => {
        this.logger.warn('produceAudioStream() | @close for mic');
        this._stopAudioProducer().catch(this.logger.error);
      });
    } catch (error) {
      this.logger.error('Error enabling mic', { error });
      throw new Error('Error enabling mic');
    }
  };

  /**
   * @description - Send a Produce event from the client to the server
   * @param streamData - Data to be sent to the server to be broadcasted to the room
   */
  protected _produceVideoStream = async <T>(
    stream: MediaStream,
    targetPeerIds: TTargetPeerIds = '*'
  ) => {
    try {
      if (!stream) {
        this.logger.warn('produceVideoStream() | stream is undefined');
        return Promise.reject();
      }

      if (!this._videoStream) {
        this._videoStream = stream;
      }

      if (this._videoProducer) {
        this.logger.warn(
          'handleCreateWebRTC() | Video Producer already exists'
        );
        return;
      }

      const track = stream.getVideoTracks()[0];

      if (!track) {
        this.logger.warn('produceVideoStream() | Track is undefined');
        return;
      }

      const producer = await this._produce(track, 'cam', targetPeerIds);

      if (!producer) {
        this.logger.warn('produceVideoStream() | Producer create error');
        return;
      }

      if (!producer.track) {
        this.logger.warn('produceVideoStream() | Producer track is undefined');
        return;
      }

      this._videoProducer = producer;
      this._videoProducer.on('trackended', () => {
        this.logger.warn('enabelCam() | trackended for cam');
        this._stopVideoProducer().catch(this.logger.error);
      });

      this._videoProducer.on('transportclose', () => {
        this.logger.warn('produceVideoStream() | transportclose for cam');
        this._stopVideoProducer().catch(this.logger.error);
      });

      this._videoProducer.on('@replacetrack', (stream, arg2, error) => {
        this.logger.warn('produceVideoStream() | @replacetrack for cam');
      });

      this._videoProducer.on('@close', () => {
        this.logger.warn('produceVideoStream() | @close for cam');
        this._stopVideoProducer().catch(this.logger.error);
      });
    } catch (error) {
      this.logger.error('Error enabling cam', { error });
    }
  };

  protected _requestStopAudioProducer = async () => {
    try {
      if (!this._audioProducer) {
        this.logger.error(
          'requestStopAudioProducer() | audioProducer is undefined'
        );
        return;
      }

      this._socket.emit('close-producer', {
        peerId: this._meId,
        producerId: this._audioProducer.appData.momoProducerId,
        roomId: this._roomId,
        appData: {
          type: 'mic',
        },
      });

      this._audioProducer.close();
    } catch (error) {
      // this.logger.error('Error stopping audio producer', error);
    }
  };

  protected _stopAudioProducer = async () => {
    try {
      if (!this._audioProducer) {
        this.logger.warn('stopAudioProducer() | audioProducer is undefined');
        return;
      }

      // this._audioProducer.track?.stop();

      // this._audioProducer.close();

      this._audioProducer = undefined;
      this.emit('room:mic-produce-stop');

      // this._machineCB('CLIENT_MIC_PRODUCER_STOPPED');
      if (!this._audioProducer.appData.momoProducerId) {
        this.logger.warn('stopAudioProducer() | momoProducerId is undefined');
        return;
      }
    } catch (error) {
      // this.logger.error('Error stopping audio producer');
      this.logger.error({ error });
    }
  };

  protected _requestStopVideoProducer = async () => {
    try {
      if (!this._videoProducer) {
        this.logger.warn(
          'requestStopVideoProducer() | videoProducer is undefined'
        );
        return;
      }

      // this._videoProducer.track?.stop();

      this._socket.emit('close-producer', {
        peerId: this._meId,
        producerId: this._videoProducer.appData.momoProducerId,
        roomId: this._roomId,
        appData: {
          type: 'cam',
        },
      });
      // this._videoProducer.track?.stop();

      this._videoProducer.close();
    } catch (error) {}
  };

  protected _stopVideoProducer = async () => {
    try {
      if (!this._videoProducer) {
        this.logger.warn('stopVideoProducer() | videoProducer is undefined');
        return;
      }

      // this._videoProducer.track?.stop();

      // this._socket.emit('close-producer', {
      //   peerId: this._meId,
      //   producerId: this._videoProducer.appData.momoProducerId,
      //   roomId: this._roomId,
      //   appData: {
      //     type: 'cam',
      //   },
      // });

      this._videoProducer = undefined;
      this.emit('room:cam-produce-stop');
      this.logger.info('room:cam-produce-stop');

      // this._machineCB('CLIENT_CAM_PRODUCER_STOPPED');
      if (!this._videoProducer.appData.momoProducerId) {
        this.logger.warn('stopVideoProducer() | momoProducerId is undefined');
        return;
      }
    } catch (error) {
      // this.logger.error('Error stopping video producer');
      // this.logger.error({ error });
    }
  };

  protected _handleJoinSuccess = async (data: IJoinSuccessWithRoomData) => {
    try {
      this._device = new mediasoup.Device();

      await this._device.load({
        routerRtpCapabilities: data.routerRTPCapabilities,
      });

      this._socket.emit('create-webrtc-transport', {
        peerId: data.peerId,
        roomId: data.roomId,
        sctpCapabilities: this._device.sctpCapabilities,
      });

      const {
        roomMetadata: { peerIds, peerMetaData },
        role,
      } = data;

      let existingPeers = {};
      let existingPeerConsumers = {};

      peerIds.forEach(peerId => {
        const peerData = peerMetaData[peerId];
        if (!peerData)
          this.logger.error('handleJoinSuccess() | No Peer Data Found');

        const newPeer = {
          peerId,
          role: peerData.role,
          displayName: peerData?.displayName || 'No Name Found',
          avatarUrl: peerData?.avatarUrl || '/avatars/0.png',
          joinStatus: 'joined:webrtc-creating',
          isHandRaised: peerData?.isHandRaised || false,
        };

        this._peers.set(newPeer.peerId, newPeer);

        existingPeers = {
          ...existingPeers,
          [peerId]: newPeer,
        };
        existingPeerConsumers = {
          ...existingPeerConsumers,
          [peerId]: {
            mic: null,
            cam: null,
            'share-audio': null,
            'share-video': null,
          },
        };

        // this._machineCB({ type: 'CLIENT_PEER_JOINED', peer: newPeer });
      });

      this.emit('room:joined', this._peers);
      this.logger.info('room:joined', this._peers);

      // this._machineCB({
      //   type: 'CLIENT_ME_JOINED',
      //   role,
      //   existingPeers,
      //   existingPeerConsumers,
      // });
    } catch (err) {
      this.logger.error({ err });
    }
  };

  protected _handleProduceSuccess = (data: IProduceSuccess) => {
    const { peerId, producerId, roomId, appData } = data;

    if (appData.type === 'cam') {
      this._videoProducer.appData.momoProducerId = producerId;
      // this._machineCB('CLIENT_CAM_PRODUCER_SUCCESS');
      this.emit('room:cam-produce-start');
      this.logger.info('room:cam-produce-start');
    } else {
      this._audioProducer.appData.momoProducerId = producerId;
      // this._machineCB('CLIENT_MIC_PRODUCER_SUCCESS');
      this.emit('room:mic-produce-start');
      this.logger.info('room:mic-produce-start');
    }
  };

  protected _handleClientConsumer = async (data: ICreateConsumerParams) => {
    try {
      if (!this._recvTransport) {
        throw new Error('No Recv Transport');
      }

      const consumerType = data.appData.type;

      const streamId = `${data.producerId}-${
        data.appData.type === 'cam' || data.appData.type === 'mic'
          ? 'audio-video'
          : 'screenAudio-screenVideo'
      }`;

      const consumer = await this._recvTransport.consume({
        id: data.id,
        producerId: data.producerId,
        kind: data.kind,
        rtpParameters: data.rtpParameters,
        appData: { ...data.appData, peerId: data.producerPeerId },
        streamId,
      });

      this._consumers.set(consumer.id, consumer);
      const consumerData = this._peersConsumers.get(data.producerPeerId);
      this._peersConsumers.set(data.producerPeerId, {
        ...consumerData,
        [data.appData.type]: consumer.id,
      });

      const consumerToAdd: IConsumer = {
        id: consumer.id,
        track: consumer.track,
        peerId: data.producerPeerId,
        appData: {
          type: consumerType,
          producerPeerId: data.producerPeerId,
          momoProducerId: data.producerId,
        },
        connectionState: {
          stage: 'connected',
          paused: consumer.paused,
        },
      };

      if (consumerType === 'share-video') {
      }

      if (consumerType === 'cam') {
        this.emit('room:peer-produce-start', {
          peerId: data.producerPeerId,
          mediaType: 'cam',
          track: consumer.track,
        });
        this.logger.info('room:peer-produce-start', {
          peerId: data.producerPeerId,
          mediaType: 'cam',
          track: consumer.track,
        });
      }
      if (consumerType === 'mic') {
        this.emit('room:peer-produce-start', {
          peerId: data.producerPeerId,
          mediaType: 'mic',
          track: consumer.track,
        });
        this.logger.info('room:peer-produce-start', {
          peerId: data.producerPeerId,
          mediaType: 'mic',
          track: consumer.track,
        });
      }

      // this._machineCB({
      //   type: 'CLIENT_CONSUMER_CREATED',
      //   consumer: consumerToAdd,
      // });

      consumer.on('transportclose', () => {
        this._consumers.delete(consumer.id);
        this.logger.warn('client-consumer() | Transport Close');
        consumer.close();
      });

      consumer.on('trackended', () => {
        this.logger.warn('client-consumer() | Tracked Ended');
      });

      consumer.on('@pause', () => {
        this.logger.warn('client-consumer() | @pause');
      });

      consumer.on('@resume', () => {
        this.logger.warn('client-consumer() | @resume');
      });

      consumer.on('@close', () => {
        this.logger.warn('client-consumer() | @close');
        this._consumers.delete(consumer.id);
      });
    } catch (error) {
      this.logger.error('Error creating client consumer: ', { error, data });
    }
  };

  protected _handleConsumerClosed = (data: IConsumerEvent) => {
    this.emit('room:peer-produce-stop', {
      peerId: data.producerPeerId,
      mediaType: data.appData.type,
    });

    const { producerPeerId, appData } = data;

    if (!data.appData || !data.appData.type) {
      this.logger.error('Need appData and appData.type to close consumer');
      return;
    }

    const consumerId = this._peersConsumers.get(producerPeerId)?.[appData.type];

    const consumer = this._consumers.get(consumerId);

    if (consumer) {
      consumer.close();
      // this._machineCB({
      //   type: 'CLIENT_CONSUMER_CLOSED',
      //   consumerId,
      // });
    } else {
      this.logger.error(
        `handleConsumerClosed() | No consumer data of type ${appData.type} for peer ${producerPeerId}`
      );
      return;
    }
  };

  /**
   * @description Handle Create WebRTC Transport Success Response from the server with the `sendTransport` and `recvTransport` details
   * @param data - `ICreateWebRtcTSuccess` - Create WebRTC Transport Success Response from the server with the `sendTransport` and `recvTransport` details
   * @summary - Will create a new `mediasoup transport` for `send` and `recv` and set the `sendTransport` and `recvTransport` for the client.
   */
  protected _handleCreateWebRTC = async (data: ICreateWebRtcTSuccess) => {
    if (!this._device) {
      this.logger.error('Device not initialized');
      return;
    }

    try {
      this._sendTransport = this._createDeviceTransport(data, 'send');
      this._recvTransport = this._createDeviceTransport(data, 'recv');

      if (this._videoStream) {
        this._produceVideoStream(this._videoStream);
      }

      if (this._audioStream) {
        this._produceAudioStream(this._audioStream);
      }
    } catch (err) {
      console.debug({ err });
    }

    if (this._createConsumersOnJoin) {
      this._socket.emit('create-consumers-for-newpeer', {
        peerId: data.peerId,
        roomId: data.roomId,
      });
    }
  };

  /**
   * @description create mediasoup device and load SDP info from the server to make it ready for use
   * @param data data from server containing transport SDP info (icePrams, iceCandidates, dtlsParameters, sctpParameters)
   * @param transportType send or recv transport
   * @returns mediasoup transport object
   * @summary this function is called when the client receives `create-webrtc-success`
   * event from the server  (after join-success) and is used to create send and recv transports
   * for the client to use to send and receive media also have `connect-webRtcTransport` event
   * which is used to connect the transport to the server and start sending and receiving media
   * when we produce a media stream (audio or video) `transport.on('produce')` event is fired
   */
  protected _createDeviceTransport = (
    data: ICreateWebRtcTSuccess,
    transportType: 'send' | 'recv'
  ) => {
    let transport: mediasoup.types.Transport | undefined;

    if (this._device === undefined) {
      this.logger.warn(
        'createDeviceTransport() | mediasoup device is undefined'
      );
      return;
    }

    if (transportType === 'send') {
      console.debug('createDeviceTransport() | creating send transport');
      transport = this._device.createSendTransport({
        id: data.sendTransportSDPInfo.id,
        iceParameters: data.sendTransportSDPInfo.iceParameters,
        iceCandidates: data.sendTransportSDPInfo.iceCandidates as any, // TS error here, donno why mediasoup haven't fixed it yet
        dtlsParameters: data.sendTransportSDPInfo.dtlsParameters,
        sctpParameters: data.sendTransportSDPInfo.sctpParameters,
        iceServers: this._turn,
        proprietaryConstraints: {},
        appData: {},
      });
    } else if (transportType === 'recv') {
      console.debug('createDeviceTransport() | creating recv transport');
      transport = this._device.createRecvTransport({
        id: data.recvTransportSDPInfo.id,
        iceParameters: data.recvTransportSDPInfo.iceParameters,
        iceCandidates: data.recvTransportSDPInfo.iceCandidates as any, // TS error here, donno why mediasoup haven't fixed it yet
        dtlsParameters: data.recvTransportSDPInfo.dtlsParameters,
        sctpParameters: data.recvTransportSDPInfo.sctpParameters,
        iceServers: this._turn,
        proprietaryConstraints: {},
        appData: {},
      });
    }

    if (transport === undefined) {
      this.logger.warn(
        'createDeviceTransport() | transport is undefined , transportType is incorrect'
      );
      return;
    }

    transport.on('connect', ({ dtlsParameters }, callback, errback) => {
      try {
        this._socket.emit('connect-webrtc-transport', {
          dtlsParameters,
          transportType,
          peerId: data.peerId,
          roomId: data.roomId,
        });
        // TODO: Handle errors here and in other callbacks below
        callback();
      } catch (err) {
        errback(err as Error);
      }
    });

    transport.on(
      'produce',
      async ({ kind, rtpParameters, appData }, callback, errback) => {
        try {
          const { id } = this._socket.emit('transport-produce', {
            peerId: data.peerId,
            roomId: data.roomId,
            producerData: {
              kind,
              paused: false,
              transportId: transport?.id,
              rtpParameters,
            },
            appData: {
              type: appData.type as MediaTypes,
              producerPeerId: data.peerId,
              targetPeerIds: appData.targetPeerIds as TTargetPeerIds,
            },
          });
          callback({ id });
        } catch (err) {
          console.debug({ err });
          errback(err as Error);
        }
      }
    );

    transport.on(
      'producedata',
      async (
        { sctpStreamParameters, label, protocol, appData },
        callback,
        errback
      ) => {
        try {
          const { id } = this._socket.emit('transport-produce-data', {
            peerId: data.peerId,
            roomId: data.roomId,
            producerData: {
              transportId: transport?.id,
              sctpStreamParameters: {
                streamId: sctpStreamParameters.streamId,
                maxPacketLifeTime: sctpStreamParameters.maxPacketLifeTime,
                maxRetransmits: sctpStreamParameters.maxRetransmits,
                ordered: sctpStreamParameters.ordered,
              },
              label,
              protocol,
              appData,
            },
          });

          callback({ id });
        } catch (error: any) {
          console.debug({ error });
          errback(error);
        }
      }
    );

    return transport;
  };
}

export class HuddleWebClient extends MediasoupClient {
  constructor() {
    super();
    this._meId = `peerId-${nanoid()}`;
    this._peers = new Map();
  }

  protected async _joinLobby(
    projectId: string,
    roomId: string,
    accessToken?: string,
    type: 'recorder' | 'user' = 'user'
  ) {
    this.logger.info('joinLobby() | joining lobby');

    if (!roomId) {
      throw new Error('Error: joinLobby() | No roomId passed');
    }

    if (type === 'recorder') this._meId = `botId-${nanoid()}`;

    this._roomId = roomId;

    const token = accessToken || (await getAuthToken());

    if (!token) {
      throw new Error('joinLobby() | HuddleWebClient.ts | No token found');
    }

    this._socket = io(`${ENDPOINT}/${roomId}`, {
      reconnectionAttempts: 10,
      auth: cb =>
        cb({
          peerId: this._meId,
          token,
          projectId,
        }),
    });

    this._socket.on('connect', () => this._handleConnect());
    this._socket.on('join-success', data => this._handleJoinSuccess(data));

    this._socket.on('disconnect', () => this._handleDisconnect());
    this._socket.on('connect_error', error => {
      this._handleConnectError(error);
    });

    this._socket.io.on('reconnect', attemptNumber => {
      this.logger.warn('reconnecting', attemptNumber);
    });

    this._socket.io.on('reconnect_attempt', attemptNumber => {
      console.log('reconnect_attempt', attemptNumber);
    });

    this._socket.io.on('reconnect_error', error => {
      console.log('reconnect_error', error);
      this.logger.error({ error });
    });

    this._socket.io.on('reconnect_failed', () => {
      console.log('reconnect_failed');
    });

    // mediasoup
    this._socket.on('create-webrtc-success', data =>
      this._handleCreateWebRTC(data)
    );

    this._socket.on('transport-produce-success', data =>
      this._handleProduceSuccess(data)
    );

    this._socket.on('create-client-consumer', data =>
      this._handleClientConsumer(data)
    );
    // this._socket.on(
    //   'create-client-data-consumer',
    //   this._handleClientDataConsumer
    // );
    this._socket.on('consumer-closed', data =>
      this._handleConsumerClosed(data)
    );

    // room
    this._socket.on('new-peer', data => this._handleNewPeer(data));
    this._socket.on('peer-left', data => this._handlePeerLeft(data));

    this._socket.on('meeting-ended', () => {
      this._close();
    });
    this._socket.on('kicked', () => {
      this._close();
    });

    this._socket.on('recording-started', () => {
      console.log('HuddleWebClient | recording-started');
      this.emit('room:recording-started');

      // this._machineCB('CLIENT_STARTED_RECORDING');
    });
    this._socket.on('recording-stopped', () => {
      console.log('HuddleWebClient | recording-stopped');
      console.log('CLIENT_STOPPED_RECORDING');
      // this._machineCB('CLIENT_STOPPED_RECORDING');
      this.emit('room:recording-stopped');

      //TODO: ingnore for hosts
    });
    this._socket.on('recording-data', (data: TRecData) => {
      console.log('RECORDING DATA', { data });
      this.emit('room:recording-data', data);

      // this._machineCB({ type: 'CLIENT_RECORDING_DATA', recordingData: data });
    });

    // // LiveStream
    this._socket.on('livestream-started', data => {
      console.log('LIVESTREAM STARTED', { data });
      // this._machineCB({
      //   type: 'CLIENT_STARTED_STREAMING',
      //   livestreamData: {
      //     livestreamingPlatform: data.platform,
      //     playbackId: data.playbackId,
      //   },
      // });
    });

    this._socket.on('livestream-stopped', () => {
      // this._machineCB('CLIENT_STOPPED_STREAMING');
    });

    this._socket.on(
      'peer-updated-displayName',
      this.__handlePeerUpdatedHandleName
    );

    this._socket.on('update-target-peerIds', this._handleUpdateTargetPeerIds);
  }

  protected async _joinRoom(consumeOnJoin: boolean = true) {
    console.info(`roomJoinRequest() | Joining room ${this._roomId}`);

    this._createConsumersOnJoin = consumeOnJoin;

    return new Promise((resolve, reject) => {
      this._socket.emit('join-request', data => {
        if (data.success) {
          resolve(data);
        } else {
          this.logger.error(
            'roomJoinRequest() | Error joining room',
            data.data
          );

          this.emit('room:failed', data.data);

          reject(data);
        }
      });
    });
  }

  protected _leaveRoom() {
    this._socket.emit('endMeeting');
    this._close();
  }

  private _close() {
    if (!this._socket) return;

    this._videoProducer?.track?.stop();
    this._audioProducer?.track?.stop();

    this._stopVideoProducer();
    this._stopAudioProducer();

    this._recvTransport?.close();
    this._sendTransport?.close();
    this._disconnectSocket();
    this._audioStream?.getTracks().forEach(track => track.stop());
    this._videoStream?.getTracks().forEach(track => track.stop());
    this._audioStream = null;
    this._videoStream = null;

    // this._machineCB('CLIENT_ROOM_LEFT');
  }

  protected _disconnectSocket() {
    this._socket.disconnect();
  }

  /**
   * @description Handle Peer Leaving Response from the server with the `peerId` and `displayName` and `avatarUrl`
   * @param peerId - `peerId` - Peer Id of user who left the meeting
   */
  protected _handlePeerLeft = ({ peerId }: { peerId: string }) => {
    // this._machineCB({ type: 'CLIENT_PEER_LEFT', peerId });
    this._peers.delete(peerId);
    console.info('_handlePeerLeft() | Peer Left', { peerId });
    this.emit('room:peer-left', peerId);
  };

  /**
   * @description Handle New Peer Joined Response from the server with the `peerId` and `displayName` and `avatarUrl`
   * @param data - `INewPeer` - New Peer Joined Response from the server with the `peerId` and `displayName` and `avatarUrl`
   */
  protected _handleNewPeer = (data: INewPeer) => {
    if (data.peerId === this._meId) {
      // cb({ type: 'CLIENT_ROOM_JOINED' });
      return;
    }

    const newPeer = {
      peerId: data.peerId,
      displayName: data.displayName,
      avatarUrl: data.avatarUrl || '',
      joinStatus: 'joined:webrtc-creating',
      isHandRaised: data.isHandRaised || false,
      role: data.role,
    };

    this.emit('room:peer-joined', newPeer);

    // this._machineCB({ type: 'CLIENT_PEER_JOINED', peer: newPeer });

    this._peers.set(data.peerId, newPeer);

    // if (data.peerId.includes('botId'))
    //   this._machineCB({ type: 'CLIENT_STARTED_RECORDING' });

    console.log(data.peerId, {
      newPeer,
      peers: this._peers,
    });
  };

  protected _handleUpdateTargetPeerIds = (data: TUpdateTargetPeerId) => {
    let producer: Producer;
    switch (data.mediaType) {
      case 'cam':
        producer = this._videoProducer;
        break;

      case 'mic':
        producer = this._audioProducer;
        break;
    }
    if (!producer || !Array.isArray(producer.appData.targetPeerIds)) return;
    if (data.operation === 'add')
      producer.appData.targetPeerIds.push(data.consumingPeerId);
    else if (data.operation === 'remove') {
      const index = producer.appData.targetPeerIds.indexOf(
        data.consumingPeerId
      );
      if (index > -1) producer.appData.targetPeerIds.splice(index, 1);
    }
  };

  /**
   *  @description:  Recording Handlers.
   *  @param sourceUrl - `string` - Source URL of the stream to be recorded.
   *  @returns `Promise` - Promise with the recording data.
   */
  protected _startRecording = async (sourceUrl: string) => {
    if (!sourceUrl)
      throw new Error('startRecording() | No sourceUrl provided.');

    if (sourceUrl.includes('localhost')) {
      throw new Error(
        'startRecording() | Recording cannot be started on localhost.'
      );
    }

    console.log('HuddleWebClient | startRecording() | Starting recording', {
      sourceUrl,
    });

    return new Promise((resolve, reject) => {
      this._socket.emit('start-recording', { sourceUrl }, data => {
        if (data.success) {
          resolve(data);
        } else {
          this.logger.error(
            'startRecording() | Error starting recording',
            data.error
          );
          reject(data);
        }
      });
    });
  };

  /**
   *  @description:  Recording Handlers.
   */
  protected _stopRecording = async (ipfs = false) => {
    return new Promise((resolve, reject) => {
      this._socket.emit('stop-recording', { ipfs }, data => {
        if (data.success) {
          resolve(data);
        } else {
          this.logger.error(
            'stopRecording() | Error in stopping recording',
            data.data
          );

          reject(data);
        }
      });
    });
  };

  /**
   *  @description:  Livestream Handlers.
   *  @param streamPayload - `TStartLivestream` - Payload for starting the livestream.
   *  @returns `Promise` - Promise with the livestream data.
   */
  protected _startStreaming = async (streamPayload: TStartLivestream) => {
    if (!streamPayload.sourceUrl)
      throw new Error('startStreaming() | No sourceUrl provided.');

    if (streamPayload.sourceUrl.includes('localhost')) {
      throw new Error(
        'startStreaming() | Streaming cannot be started on localhost.'
      );
    }

    return new Promise((resolve, reject) => {
      this._socket.emit('start-livestream', streamPayload, data => {
        if (data.success) {
          // this.setLivetreamState('starting');
          resolve(data);
        } else {
          this.logger.error(
            'startStreaming() | Error in starting streaming',
            data.data
          );

          reject(data);
        }
      });
    });
  };

  protected _stopStreaming = async () => {
    return new Promise((resolve, reject) => {
      this._socket.emit('stop-livestream', null, data => {
        if (data.success) {
          // this.setLivetreamState('idle');
          resolve(data);
        } else {
          this.logger.error(
            'stopStreaming() | Error in stopping streaming',
            data.data
          );
          reject(data);
        }
      });
    });
  };

  // app utils
  protected _setDisplayName = (displayName: string) => {
    const namePayload: IPeerSocketdata<'displayName'> = {
      data: {
        displayName: {
          name: displayName,
        },
      },
      peerId: this._meId,
    };

    return new Promise((resolve, reject) => {
      this._socket.emit('peer-set-displayName', namePayload, data => {
        if (data.success) {
          resolve(displayName);
        } else {
          reject(data);
        }
      });
    });
  };

  protected _createSingleConsumer = (data: TCreateSingleConsumer) => {
    if (data.producerPeerId === this._meId) return;
    console.log('createSingleConsumer() |', data);
    this._socket.emit('create-single-consumer', data);
  };

  protected _closeSingleConsumer = (data: TCreateSingleConsumer) => {
    const consumers = this._peersConsumers.get(data.producerPeerId);

    if (!consumers || !consumers[data.mediaType]) return;

    console.log('HuddleWebCLient | closeSingleConsumer() |', {
      consumerIdtoCLose: consumers[data.mediaType],
    });

    this._socket.emit('close-consumer', {
      peerId: this._meId,
      roomId: this._roomId,
      consumerId: consumers[data.mediaType],
      mediaType: data.mediaType,
      producerPeerId: data.producerPeerId,
    });
    console.log('closeSingleConsumer() | sent');
  };

  private __handlePeerUpdatedHandleName = (
    data: IPeerSocketdata<'displayName'>
  ) => {
    console.log(
      'handlePeerUpdatedHandleName',
      data.data.displayName.name,
      data.peerId
    );

    // this._machineCB({
    //   type: 'CLIENT_DISPLAY_NAME_CHANGED',
    //   displayName: data.data.displayName.name,
    //   peerId: data.peerId,
    // });
  };
}
