import type { RtpEncodingParameters } from 'mediasoup-client/lib/types';

export const VIDEO_CONSTRAINS = {
  qvga: { width: { ideal: 320 }, height: { ideal: 240 } },
  vga: { width: { ideal: 640 }, height: { ideal: 480 } },
  hd: { width: { ideal: 1280 }, height: { ideal: 720 } },
};

export const PC_PROPRIETARY_CONSTRAINTS = {
  optional: [{ googDscp: true }],
};

export const WEBCAM_SIMULCAST_ENCODINGS: RtpEncodingParameters[] = [
  {
    scaleResolutionDownBy: 4,
    maxBitrate: 300000, // 300kbps
    rid: 'r0',
    scalabilityMode: 'S1T3',
    maxFramerate: 15,
  },
  {
    scaleResolutionDownBy: 2,
    maxBitrate: 600000, // 600kbps
    rid: 'r1',
    scalabilityMode: 'S1T3',
    maxFramerate: 30,
  },
  {
    scaleResolutionDownBy: 1,
    maxBitrate: 9000000, // 900kbps
    rid: 'r2',
    scalabilityMode: 'S1T3',
    maxFramerate: 30,
  },
];

export const SCREEN_SHARING_SIMULCAST_ENCODINGS: RtpEncodingParameters[] = [
  {
    maxBitrate: 1000000, // 1mbps
    networkPriority: 'high',
    maxFramerate: 30,
    dtx: true,
  },
];

export const encodingViaMediaType = {
  cam: WEBCAM_SIMULCAST_ENCODINGS,
  'share-video': SCREEN_SHARING_SIMULCAST_ENCODINGS,
  mic: undefined,
  'share-audio': undefined,
};

export const codecOptionsViaType = {
  audio: { opusStereo: true, opusFec: true, opusDtx: true },
  video: { videoGoogleStartBitrate: 1000 },
};

export const WEBCAM_KSVC_ENCODINGS = [{ scalabilityMode: 'S3T3_KEY' }];
export const SCREEN_SHARING_SVC_ENCODINGS = [
  { scalabilityMode: 'S3T3', dtx: true },
];
