import { HuddleClient } from './client/HuddleClient';
import { THClientEvents } from './client/HuddleWebClient';
import { IPeer } from './client/HuddleWebClientTypes';

function sayHello() {
  console.log('hello');
}
const huddleClient = new HuddleClient();

export type TEvents = THClientEvents;

export { huddleClient, sayHello, IPeer };
