import axios from 'axios';

import { IDS_URL } from '../constants';

export const getAuthToken = async () => {
  try {
    const {
      data: {
        result: {
          data: {
            json: { accessToken },
          },
        },
      },
    } = await axios.post(`${IDS_URL}/trpc/auth.guest`);

    return accessToken;
  } catch (error) {
    console.log({ error });
  }
};
