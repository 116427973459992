export default class Logger {
  private logging: boolean = true;

  info = (...args: unknown[]) => {
    if (!this.logging) return;

    console.info(...args);
  };
  warn = (...args: unknown[]) => {
    if (!this.logging) return;

    console.warn(...args);
  };
  error = (...args: unknown[]) => {
    if (!this.logging) return;

    console.error(...args);
  };

  disable = () => (this.logging = false);
  enable = () => (this.logging = true);
}
